import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { LazyTooltip } from '@alkem/react-ui-tooltip';

import { getProductGTINWithFallbackOnId } from 'core/api/productversion';
import { hasAnyProductUpdatePermission, isMaker } from 'core/api/user';
import { AssignProductToTargetMarket } from 'modules/product-creation';
import { selectOrganizationTargetMarkets } from 'modules/user';
import { regularReferentialApi as referentialApi } from 'resources/referentialApi';
import searchApi from 'resources/searchApi';
import i18n from 'utils/i18n';
import { logError } from 'utils/logging';

const isConsumerOrDisplayUnit = (product) =>
  product.get('isConsumerUnit') || product.get('isDisplayUnit');

const getSelectedId = (selectedMap) => selectedMap.keySeq().first();

const mapStateToProps = (state) => ({
  targetMarkets: selectOrganizationTargetMarkets(state),
});

export class AssignToNewMarketAction extends Component {
  static propTypes = {
    selectedMap: ImmutablePropTypes.map.isRequired,
    productMap: ImmutablePropTypes.map.isRequired,
    targetMarkets: ImmutablePropTypes.list.isRequired,
    dispatch: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static shouldBeDisplayed = ({ user }) =>
    isMaker(user) && hasAnyProductUpdatePermission(user);

  static isEnabled = ({ productMap, selectedMap }) =>
    selectedMap.size === 1 &&
    isConsumerOrDisplayUnit(productMap.get(getSelectedId(selectedMap)));

  state = {
    createdForAllTargetMarkets: false,
    isLoading: true,
    remainingTargetMarkets: [],
    showProductCreation: false,
  };

  componentDidMount() {
    this.fetchDetails();
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  getReference() {
    const { selectedMap, productMap } = this.props;
    const productVersion = selectedMap
      .map((isSelected, pvId) => productMap.get(pvId))
      .toList()
      .first();
    return getProductGTINWithFallbackOnId(productVersion);
  }

  updateRemainingTargetMarkets = (versions = []) => {
    const { targetMarkets } = this.props;
    if (versions.length === targetMarkets.size) {
      this.setState({ createdForAllTargetMarkets: true });
    } else {
      const createdForTmIds = versions.map(
        (version) => version.tags.targetMarket.id,
      );
      const remainingTargetMarkets = targetMarkets
        .map((tm) => tm.get('id'))
        .filter((tmId) => !createdForTmIds.includes(tmId))
        .toJS();
      this.setState({ remainingTargetMarkets });
    }
  };

  async fetchDetails() {
    try {
      const response = await searchApi.GetProductVersionList({
        queries: {
          gtinsIn: this.getReference(),
          sourceInclude: [
            'isDisplayUnit',
            'kind',
            'tags.targetMarket.id',
            'typePackaging.id',
          ],
        },
        sort: null,
      });
      if (this.isCancelled) {
        return;
      }
      const selectedId = Object.keys(this.props.selectedMap.toJS())[0];
      const versions = get('data.data', response);
      if (versions) {
        this.updateRemainingTargetMarkets(versions);
        const pv = versions.find((version) => version.id === selectedId);
        const kindId = get('kind.id', pv);
        // we might have no kind due to indexing latency
        if (kindId) {
          // we (re-)fetch the kind to have the description in the correct language
          const r2 = await referentialApi.ReferentialGet('kinds', kindId);
          const kind = r2.data.data;
          this.setState({ pv, kind });
        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      logError(error);
    }
  }

  showProductCreation = () => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }
    this.setState({ showProductCreation: true });
  };

  render() {
    const {
      createdForAllTargetMarkets,
      isLoading,
      remainingTargetMarkets,
      showProductCreation,
      pv,
      kind,
    } = this.state;

    if (isLoading) {
      return null;
    }

    const label = i18n.t('Assign to a new market');

    return createdForAllTargetMarkets ? (
      <LazyTooltip
        id="new-markets"
        tooltipLabel={i18n.t('product already on all markets')}
        place="bottom"
        block
      >
        <div className="ActionOption--link-disabled">
          <div className="ActionOption">{label}</div>
        </div>
      </LazyTooltip>
    ) : (
      <div className="ActionOption" onClick={this.showProductCreation}>
        {label}
        {showProductCreation && (
          <AssignProductToTargetMarket
            gtin={this.getReference()}
            pv={pv}
            kind={kind}
            remainingTargetMarkets={remainingTargetMarkets}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(AssignToNewMarketAction);
