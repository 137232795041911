import { string } from 'prop-types';

import i18n from 'utils/i18n';
import { formatMedia } from 'utils/links';

import PreviewTemplate from '../preview';

const FeatureProductBulkEditPreview = ({ imageSrc }) => (
  <PreviewTemplate
    previewImage={imageSrc}
    paragraphs={[
      i18n.t(
        'The bulk edition feature allows you to update common attributes of multiple products at once!',
      ),
      i18n.t(
        'Boost your productivity, by upgrading to one of our enterprise packages!',
      ),
      i18n.t('Product duplication, export to retailers,...'),
      i18n.t(
        'Unleash your productivity with Alkemics extended features. Contact us for further information.',
      ),
    ]}
    withStrongText
  />
);

FeatureProductBulkEditPreview.propTypes = {
  imageSrc: string,
};
FeatureProductBulkEditPreview.defaultProps = {
  imageSrc: formatMedia('/stream/features/bulkedit.gif'),
};

export default FeatureProductBulkEditPreview;
