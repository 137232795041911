import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { useWindowSize } from '@alkem/react-hooks';

import prefs from 'constants/prefs';
import {
  hasSupplierDirectory,
  hasViewSourcing,
} from 'core/api/organization-settings';
import { getOrganizationSettings, isRetailer } from 'core/api/user';
import { hasFeature } from 'modules/feature-flag';
import {
  RELEASE_MANUFACTURER_IMPORT_TAB,
  RELEASE_PW_TEMPLATE,
} from 'modules/feature-flag/constants';
import { hasImportsGenericFeature } from 'modules/imports-generic/utils';
import { getAll as selectRecipients } from 'modules/recipients/reducers';
import {
  hasRfpForManufacturer,
  hasRfpForRetailer,
  selectHasRfpModule,
} from 'modules/rfp';
import { hasSUTPermissions } from 'modules/sharing-unit-tariffs';
import { selectHasNielsenApp } from 'modules/user';
import { RecipientsImmutable, UserImmutable } from 'types';
import { track } from 'utils/tracking';

import { NavbarItem } from '../item';

import './style.scss';
import { TabItemType } from './types';
import { useTabs } from './use-tabs';

interface Props {
  hasSupplierDirectoryTab?: boolean;
  navBarType: string;
  user: UserImmutable;
}

export function NavbarTabs({ navBarType, user }: Props) {
  const hasNielsenAppEnabled = useSelector(selectHasNielsenApp);
  const recipients: RecipientsImmutable = useSelector(selectRecipients);
  const hasRfpModule = useSelector(selectHasRfpModule);

  const organizationSettings = getOrganizationSettings(user);
  const fullTabsRef = useRef<null | HTMLUListElement>(null);
  const tabsRef = useRef<null | HTMLUListElement>(null);
  const [showMore, setMore] = useState(false);

  const tabFilter = useMemo(
    () => ({
      brands: false, // dead route, but can be present in prefs from database
      productnetwork: hasViewSourcing(organizationSettings),
      rfpV2:
        hasRfpForRetailer(user) ||
        hasRfpForManufacturer(user, recipients, hasRfpModule),
      sharingUnitTariffs:
        hasSUTPermissions(user, recipients) &&
        !hasFeature(user, RELEASE_PW_TEMPLATE),
      sharingUnitTemplatesAndTariffs:
        hasSUTPermissions(user, recipients) &&
        hasFeature(user, RELEASE_PW_TEMPLATE),
      sharingUnitTemplates:
        !hasSUTPermissions(user, recipients) &&
        hasFeature(user, RELEASE_PW_TEMPLATE),
      nielsen: !isRetailer(user) && hasNielsenAppEnabled,
      suppliers: isRetailer(user) && hasSupplierDirectory(organizationSettings),
      imports: hasFeature(user, RELEASE_MANUFACTURER_IMPORT_TAB),
      importsGeneric: hasImportsGenericFeature(user),
    }),
    [
      hasNielsenAppEnabled,
      hasRfpModule,
      organizationSettings,
      recipients,
      user,
    ],
  );

  const navbarPreference = useMemo(
    () => (prefs.navbar && prefs.navbar[navBarType]) || [],
    [navBarType],
  );

  const navigationTabs: TabItemType[] = useMemo(() => {
    return navbarPreference
      .map(({ key, ...navigationTab }) => ({
        ...navigationTab,
        id: key,
        ...(!tabFilter.sharingUnitTemplatesAndTariffs &&
          navigationTab.path === '/templates' && {
            onClick: () => {
              track({ category: 'listing', action: 'bulknetprice_clicked' });
            },
          }),
      }))
      .filter(({ id }) => tabFilter[id] || !(id in tabFilter));
  }, [navbarPreference, tabFilter]);

  const windowSize = useWindowSize();

  const { visibleTabs, hiddenTabs } = useTabs(
    navigationTabs,
    tabsRef,
    fullTabsRef,
    windowSize.width,
  );

  return (
    <>
      <ul
        ref={tabsRef}
        className="Navbar__menu nav navbar-nav"
        data-testid="nav-bar-tabs"
      >
        {visibleTabs.map((tab) => (
          <NavbarItem key={tab.id} {...tab} />
        ))}
        {hiddenTabs.length ? (
          <li
            className="nav-item Navbar__more"
            onMouseEnter={() => setMore(true)}
            onMouseLeave={() => setMore(false)}
          >
            <i
              className="mdi mdi-menu Navbar__moreIcon"
              data-testid="more-icon"
            />
            {showMore && (
              <ul className="Navbar__moreDropdown" data-testid="more-dropdown">
                {hiddenTabs.map((tab) => (
                  <NavbarItem key={tab.id} {...tab} />
                ))}
              </ul>
            )}
          </li>
        ) : null}
      </ul>
      {/* DO NOT REMOVE: Hidden tabs used to calculate the menu max width  */}
      <ul
        ref={fullTabsRef}
        className="Navbar__menu Navbar__menu--full nav navbar-nav"
      >
        {navigationTabs.map((tab) => (
          <NavbarItem key={tab.id} {...tab} noIds />
        ))}
      </ul>
    </>
  );
}
