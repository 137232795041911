/**
 * Holds all features constants that need to be checked across Product Stream
 * Visit https://admin.alkemics.com/settings to see the features
 */

// From the Product Create page, allows/forbids duplication of an existing product
export const FEATURE_MANUFACTURER_DUPLICATION =
  'feature-manufacturer-duplication';

// From the Product List page, allows/forbids bulk edit of products
export const FEATURE_MANUFACTURER_BULKEDIT = 'feature-manufacturer-bulkedit';

// Allows adding recipients to multiple products, from catalog
export const FEATURE_MANUFACTURER_BULK_ADD_RECIPIENTS =
  'feature-manufacturer-bulk-add-recipient';

// Allows publishing multiple products from catalog
export const FEATURE_MANUFACTURER_BULK_PUBLISH =
  'feature-manufacturer-bulk-publish';

export const FEATURE_MANUFACTURER_IMPORT_FABDIS =
  'feature-manufacturer-import-fabdis';

// For manufacturer, in the history page, display the new version of the history
export const FEATURE_ALL_HISTORY = 'feature-all-history';

// For both manufacturer and retailer, enum toggle to define the permission level
export const FEATURE_PERMISSION_MANAGEMENT =
  'feature-all-permission-management';
// previous feature values:
export const FEATURE_PERMISSION_MANAGEMENT_NONE = 'none';
export const FEATURE_PERMISSION_MANAGEMENT_GOVERNANCE = 'governance';
export const FEATURE_PERMISSION_MANAGEMENT_HIERARCHY = 'hierarchy';
export const FEATURE_PERMISSION_MANAGEMENT_KEYS = [
  FEATURE_PERMISSION_MANAGEMENT_NONE,
  FEATURE_PERMISSION_MANAGEMENT_GOVERNANCE,
  FEATURE_PERMISSION_MANAGEMENT_HIERARCHY,
];
export const FEATURE_ORGANIZATION_MANAGE_MAILDOMAIN =
  'feature-organization-manage-maildomain';
// For manufacturer, allow sharing recipe or not with manufacturers
export const FEATURE_MANUFACTURER_RECIPE = 'feature-manufacturer-recipe';
export const FEATURE_MANUFACTURER_VIDEO = 'feature-manufacturer-video';
export const FEATURE_VIDEO_DYNAMIC_FIELDS = 'video-dynamic-fields';
export const RELEASE_BRAINSONIC_DECOMMISSION =
  'release-brainsonic-decommission';

export const FEATURE_RETAILER_DOWNLOAD_PACKSHOT =
  'feature-retailer-download-packshot';

export const FEATURE_REPORTING_OVERVIEW = 'feature-reporting-overview';

export const FEATURE_REPORTING_STICKY_FIRST_ROW_AND_COLUMN =
  'reporting-sticky-first-row-and-column';

export const FEATURE_RETAILER_INTERNAL_MULTI_CATEGORIZATION =
  'feature-retailer-internal-multi-categorization';

export const FEATURE_RETAILER_LIFECYCLEFILTER =
  'feature-retailer-lifecyclefilter';

// Catalog filters
export const FEATURE_ALL_CATALOG_FILTER_EXPORTABLE =
  'feature-all-catalog-filter-exportable';

export const FEATURE_ALL_CATALOG_FILTER_QUALITY =
  'feature-all-catalog-filter-quality';

export const FEATURE_ALL_CATALOG_FILTER_STATUS =
  'feature-all-catalog-filter-status';

export const FEATURE_EXP_GDSNPARTICIPATIONTERMS =
  'feature-exp-gdsnparticipationterms';

export const FEATURE_ALL_USERLABEL_MANAGEMENT =
  'feature-all-userlabel-management';

export const FEATURE_CATALOG_FILTER_MULTI_SOURCE =
  'feature-catalog-filter-multi-source';

export const FEATURE_NIELSEN_MARKET = 'feature-nielsen-market';

export const FEATURE_NPT_NIELSEN_UPSELL = 'feature-npt-nielsen-upsell';

export const FEATURE_CATALOG_FILTER_KIND = 'feature-catalog-filter-kind';
// Import features
export const FEATURE_MANUFACTUER_IMPORT_XLSX_GENERIC =
  'feature-manufacturer-import-xlslx';

export const FEATURE_MANUFACTURER_IMPORT_XLSX_LECLERC =
  'feature-manufacturer-import-xlsx-leclerc';

export const RELEASE_MANUFACTURER_IMPORT_TAB =
  'release-manufacturer-import-tab';

export const FEATURE_MANUFACTUER_IMPORT_XLSX_V2 =
  'feature-manufacturer-import-xlsx2';

// Physical Check flags
export const FEATURE_THIRD_PARTY_PHYSICAL_CHECK =
  'feature-third-party-physical-check';

export const FEATURE_ALL_CATALOG_FILTER_GPC = 'feature-all-catalog-filter-gpc';

export const FEATURE_ALL_CATALOG_FILTER_ISDISPLAYABLEFORTARGETS =
  'feature-all-catalog-filter-is-displayable-for-targets';

export const FEATURE_RULE_SETS_SELECTION = 'feature-rule-sets-selection';

export const FEATURE_STAGE_GATES_FILTER = 'feature-stage-gates-filter';

export const FEATURE_SYNCHRONIZATION_STATUS =
  'feature-in-synchronization-status';

export const FEATURE_IN_SYNCHRONIZATION_STATUS =
  'feature-in-synchronization-status';

export const FEATURE_IN_GDSNWRITE_MANUFACTURER_GLN =
  'feature-in-gdsnwrite-manufacturergln';

export const FEATURE_MULTI_TARGETMARKET_CATALOG =
  'feature-multi-targetmarket-catalog';

export const FEATURE_PUBLIC_API = 'feature-all-public-api';

export const FEATURE_HPP = 'feature-hpp';

// PONZI

export const FEATURE_VIEWAS_SELECTOR = 'viewas-selector';

export const FEATURE_PNZ_BULK_VALIDATE = 'feature-pnz-retailer-bulkvalidate';
export const FEATURE_PRICEWATERFFAL_ESTIMATION =
  'feature-pricewaterfall-estimation';

// Release to handle tariffs and templates
export const PALPA_LISTING_TEMPLATE_TYPE = 'palpa-listing-template-type';

/**
 * ROLLOUT
 */

export const FEATURE_KIND_FULL_TREE = 'knd-full-tree';

export const FEATURE_NO_GTIN_PRODUCTS = 'feature-no-gtin-products';

export const FEATURE_PRO_VALIDATION_SUGGESTED_FIELD =
  'feature-pro-validation-suggested-fields';

export const FEATURE_PRO_RETAILER_FIELD_FLAG =
  'feature-pro-retailer-field-flag';

export const FEATURE_PRO_SUGGESTION_BLOCK = 'feature-pro-suggestion-block';

export const FEATURE_MESSAGE_CATALOG = 'message-catalog';

export const FEATURE_TARGET_CATALOG_IMPORT = 'target-catalog-import';

export const FEATURE_TARGET_CATALOG_IMPORT_PRODUCT =
  'target-catalog-import-product';

export const FEATURE_PRODUCT_UPDATE_EMAILS = 'product-update-emails';

export const FEATURE_PHYSICAL_CHECK_TRIGGER_GATE =
  'physical-check-trigger-gate';

export const FEATURE_MULTI_LOCALES = 'multi-locales';

export const FEATURE_KIND_REALTIME_SUGGESTION = 'kind-realtime-suggestion';

export const FEATURE_BRAND_FILTER = 'brand-filter';

export const FEATURE_SPECIFIC_MEDIA = 'specific-media';

export const FEATURE_PRODUCTPICTURE_JSON_STORE_INPUT =
  'feature-productpicture-jsonstore-input';

export const FEATURE_SPECIFIC_PACKSHOT = 'specific-packshot';

export const FEATURE_MEDIA_IP_RIGHTS = 'media-ip-rights';

export const FEATURE_PRODUCT_UNSYNCHRONIZATION = 'product-unsynchronization';

export const FEATURE_FND_MULTILEVEL_FIELDS = 'feature-fnd-multilevel-fields';

export const PRODUCT_PAGE_LOAD_ON_DEMAND = 'product-page-load-on-demand';

export const RELEASE_ENHANCED_API_DOCUMENTATION =
  'enhanced-public-api-documentation';

export const FLAT_HIERARCHIES = 'flat-logistical-hierarchies';
export const EDITABLE_HIERARCHIES = 'editable-logistical-hierarchies';
export const FEATURE_DATA_MATURITY_MANAGEMENT = 'data-maturity-management';
export const FEATURE_DATA_MATURITY_DEADLINE = 'data-maturity-deadline';
export const RELEASE_DATA_MATURITY_UNSHARED_PRODUCTS =
  'data-maturity-unshared-products';
export const FEATURE_PRODUCT_PAGE_ACCORDION = 'feature-product-page-accordion';

export const FEATURE_PRODUCT_INSIGHTS = 'product-insights';
export const RELEASE_TEXTILE = 'textile-variants';
export const RELEASE_TEXTILE_VARIANTS_IMPORT_V2 =
  'release-textile-variants-import-v2';

export const FEATURE_NOTIFICATION_CENTER = 'notification-center';
export const FEATURE_PICTURE_DERIVATIVES_DOWNLOAD =
  'picture-derivatives-download';

export const FEATURE_DATA_OPS = 'data-ops';
export const FEATURE_DATA_OPS_RETAILER_HIERARCHIES =
  'data-ops-new-logistical-hierarchies';
export const RELEASE_DATA_OPS_MESSAGES = 'data-ops-send-messages';
export const RELEASE_DATA_OPS_MANUFACTURERS = 'data-ops-for-manufacturers';
export const RELEASE_B2K_COLLAB_EDITS_SV_SF = 'b2k-collab-edits-SV-SF';
export const RELEASE_B2K_COLLAB_EDITS_NEWFILTER = 'b2k-collab-edits-newfilter';
export const RELEASE_B2K_COLLAB_EDITS_PDP_SUMMARY =
  'b2k-collabedit-pdp-summary';

export const FEATURE_SPECIFIC_VALUES = 'specific-values';

export const FEATURE_SHARING_STATUS_FILTER = 'sharing-status-filter';

export const EXCLUSIVE_PRODUCT_ACTIVE_RANGE_SKIP_VALIDATION =
  'exclusive-product-active-range-skip-validation';

export const FEATURE_REPORTING_PAGINATION = 'reporting_pagination';

export const EDITABLE_FIRST_BRACKET_LISTING = 'editable-first-bracket-listing';
export const EDITABLE_FIRST_BRACKET_TARIFF = 'editable-first-bracket-tariff';

export const REPORTING_EXPECTED_PRODUCTS_MIGRATION =
  'reporting-expected-products-migration';

export const FEATURE_SHARING_STATUS_HISTORY = 'product-sharing-status-history';

export const FEATURE_REPORTING_SHARING_STATUS_FILTER =
  'reporting-workflow-sharing-status-filter';

export const RELEASE_PHT_TARIFF_NEW_COLUMNS = 'pht-tariff-new-columns';
export const FEATURE_PHT_TARIFF_FILTER_SEARCH = 'pht-tariff-filter-search';
export const FEATURE_PHT_RFP_MATURITIES = 'pht-rfp-maturities';
export const FEATURE_PHT_RFP_EXPORT_SELECTION = 'pht-rfp-export-selection';
export const FEATURE_PHT_RFP_EXPORT_SELECTION_v2 =
  'pht-rfp-export-selection-v2';
export const FEATURE_PHT_RFP_EXPORT_ISIS = 'pht-rfp-export-isis';
export const FEATURE_PHT_RFP_EXPORT_ALICE = 'pht-rfp-export-alice';
export const FEATURE_PHT_RFP_GENERIC_IMPORT_EXPORT =
  'pht-rfp-generic-import-export';

export const FEATURE_PHT_TARIFF_EXPORT_CSV = 'pht-tariff-export-csv';

// PERMISSIONS V3, if you add a flag, please add it to allPermissionsV3Flags
export const FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF =
  'permission-v3-sharingunittemplate';
export const FEATURE_PERMISSIONS_V3_RFP_EVENT = 'permission-v3-rfp-event';
export const FEATURE_PERMISSIONS_V3_RFP_BUSINESSUNIT =
  'permission-v3-rfp-businessunit';
export const FEATURE_PERMISSIONS_V3_PRODUCT = 'permission-v3-product';
export const FEATURE_PERMISSIONS_V3_ORGANIZATION = 'permission-v3-organization';
export const FEATURE_PERMISSIONS_V3_USERGROUPS = 'permission-v3-usergroups';
export const allPermissionsV3Flags = [
  FEATURE_PERMISSIONS_V3_PRODUCT,
  FEATURE_PERMISSIONS_V3_SHARINGUNITTARIFF,
  FEATURE_PERMISSIONS_V3_RFP_EVENT,
  FEATURE_PERMISSIONS_V3_RFP_BUSINESSUNIT,
  FEATURE_PERMISSIONS_V3_ORGANIZATION,
];

export const RELEASE_LOGISTICAL_HIERARCHIES_MASS_EDIT =
  'release-logistical-hierarchies-mass-edit';
export const RELEASE_LOGISTICAL_HIERARCHIES_STICKY_FORM_HEADER =
  'release-logistical-hierarchies-sticky-form-header';
export const RELEASE_LOGISTICAL_HIERARCHIES_ADVANCED_PRIVATE_FIELDS =
  'release-logistical-hierarchies-advanced-private-fields';

export const FEATURE_DIET_TYPE_FILTER = 'catalog-diet-type-filter';

export const FEATURE_PRODUCT_DOCUMENTS = 'product_documents';
export const FEATURE_COMPANY_PROFILE = 'company_profile';
export const FEATURE_DISALLOW_PRODUCT_CREATION = 'disallow_product_creation';
export const RELEASE_SUPPLIER_DIRECTORY_WAVES_FILTER =
  'supplier-directory-waves-filter';
export const RELEASE_SUPPLIER_DIRECTORY_CLASSIFICATIONS_FILTER =
  'release-supplier-directory-classification-filter';
export const FEATURE_CORRECTION_MESSAGE = 'feature-correction-messsage';

export const FEATURE_PRODUCT_REVIEW = 'review-engine';

export const FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT =
  'heterogeneous-logistical-units';

export const FEATURE_RETAILER_IMPORT_FROM_UI =
  'feature-retailer-import-from-ui';

export const RELEASE_BULK_ACTIVATE_RECIPIENTS =
  'release-bulk-activate-recipients';

export const RELEASE_NEW_PRODUCT_TYPE_ICONS = 'new-creation-product-type';

export const RELEASE_CASE_AS_BASE_UNIT = 'case-as-base-unit';
export const RELEASE_CASE_AS_CONSUMER_UNIT = 'case-as-consumer-unit';
export const RELEASE_PACK_AS_BASE_UNIT = 'pack-as-base-unit';

export const FEATURE_COMPLIANCE_REPORT_EXPORT = 'compliance-report-export';

export const FEATURE_SCHEDULED_APPLICATION_DATE_FILTER =
  'scheduled-application-date-filter';

export const RELEASE_PRICE_WATERFALL_EARLY_PAYMENT_DISCOUNT =
  'pricewaterfall-early-payment-discount';

export const HIDE_USER_PROFILE_MODAL = 'hide-user-profile-modal';

export const FEATURE_APPSTORE = 'show-appstore';

export const RELEASE_AOC_OFFERS_DEPRECATION = 'aoc_offers_deprecation';

export const RELEASE_PRICE_WATERFALL_TAXES_DATES_MANAGEMENT =
  'release-price-waterfall-taxes-date-management';

export const RELEASE_PRODUCTPAGE_FLYWHEEL_MEDIA =
  'release-productpage-flywheel-media';

export const RELEASE_RETAILER_DISPLAY_CONTAINING_LH_ON_BU =
  'release-retailer-display-containing-hierarchies-on-base-unit-page';

export const RELEASE_PW_TEMPLATE = 'pht-pw-template-v1';

export const USE_PRODUCTSEGMENT_LABEL_ROUTE = 'use-productsegment-label-route';

export const RELEASE_PRODUCT_PAGE_REFRACT_STRUCTURE_FETCHING =
  'release-productpage-refract-structure-fetching';
