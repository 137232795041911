import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import FormTypePackaging from 'components/ui/form/FormTypePackaging';
import AllergenTypeList from 'components/ui/form/allergen-type-list';
import FormAutocomplete from 'components/ui/form/autocomplete';
import FormBrandAutocomplete from 'components/ui/form/autocomplete/brand';
import FormKindAutocomplete from 'components/ui/form/autocomplete/kind';
import FormProductAutocomplete from 'components/ui/form/autocomplete/product';
import FormCheckbox from 'components/ui/form/checkbox';
import { LanguageConsumer } from 'components/ui/form/context';
import Date from 'components/ui/form/date';
import DateRange from 'components/ui/form/daterange';
import FormDict from 'components/ui/form/dict';
import DeclinableField from 'components/ui/form/field/declinable';
import FormFullList from 'components/ui/form/full-list';
import FormGln from 'components/ui/form/gln';
import ImageCheckbox from 'components/ui/form/image-checkbox';
import Labels from 'components/ui/form/labels';
import FormList from 'components/ui/form/list';
import FormMultiAutocomplete from 'components/ui/form/multi-autocomplete';
import MultiLevelField from 'components/ui/form/multi-level-field';
import FormProductIdentifier from 'components/ui/form/product-identifier';
import FormProductSegmentClassifier from 'components/ui/form/productsegment-classifier';
import { PSQEditor } from 'components/ui/form/psq-editor';
import FormRadio from 'components/ui/form/radio';
import SuppliedUnitFormRadio from 'components/ui/form/radio/suppliedunit-radio';
import FormSelect from 'components/ui/form/select';
import FormStringList from 'components/ui/form/string-list';
import SupplierIdRBA from 'components/ui/form/supplier-id-rba';
import FormTags from 'components/ui/form/tags';
import FormText from 'components/ui/form/text';
import FormTextarea from 'components/ui/form/textarea';
import FormTextileVariantStringList from 'components/ui/form/textile-variant-string-list';
import FormTextileVariantsList from 'components/ui/form/textile-variants-select';
import { DIMENSION_KIND, DIMENSION_TARGET_MARKET } from 'constants/dimensions';
import { ENTITY_TYPE_PRODUCTVERSION } from 'constants/entities';
import { getRootModel } from 'core/api/fields';
import { getDimensions } from 'core/api/productversion';
import withFlags from 'hocs/with-flags';
import { FEATURE_FND_MULTILEVEL_FIELDS } from 'modules/feature-flag/constants';
import PriceWaterfalls from 'modules/price-waterfalls';
import VariantList from 'modules/product-page/modules/textile/components/variant-list';
import { selectRejectedFields } from 'modules/product-page/selectors';
import LogisticalHierarchySelector from 'modules/sharing-units/components/sharing-unit/form/components/logistical-hierarchies-selector';
import { selectTargetMarketId } from 'reducers/productVersion';
import { get } from 'utils/immutable';

import { FieldErrorBoundary } from '../error';

export const isComplex = (field) =>
  field.children.some(
    (child) =>
      child.inputKind.kind === 'list' &&
      !child.children.every(
        (item) => item.model === 'data' || item.model === 'expressedIn',
      ),
  );

const MultiLevelProxy: any = withFlags(({ flags, ...props }) => {
  return flags[FEATURE_FND_MULTILEVEL_FIELDS] && isComplex(props.field) ? (
    <MultiLevelField {...props} />
  ) : (
    <FormList {...props} />
  );
});

export const extractValue = (o, model) =>
  get(o, 'edited') ? get(o, `edited.${model}`) : get(o, model);

const InternalField = ({
  fieldKey,
  entity,
  entityKind,
  entityId,
  field,
  validate = true,
  hasProductUpdatePermission = false,
  externalPlugins = null,
  extraParams = {},
}: any) => {
  const rejectedFields = useSelector(selectRejectedFields);
  const rejections = useMemo(
    () => rejectedFields[field.model],
    [field, rejectedFields],
  );

  let value;

  const {
    flags,
    patch,
    disableDataOps,
    isRetailer,
    entityPermissions,
    sourceOrganizationId,
    userOrganizationId,
  } = extraParams;

  const patchData = patch?.data?.[0];
  if (!disableDataOps && patchData && isRetailer) {
    value = extractValue(
      {
        [field.model.split('.')[0]]: patchData.dataNew,
      },
      field.model,
    );
  } else if (Array.isArray(field.model)) {
    value = field.model.map((k) => extractValue(entity, k));
  } else {
    value = extractValue(entity, field.model);
  }

  const assetId = entity.id;
  const params = {
    entityKind,
    entityId,
    field,
    key: fieldKey,
    value,
    validate,
    hasProductUpdatePermission,
    externalPlugins,
    extraParams,
    assetId,
    rejections,
  };
  const currentTargetMarketID = useSelector(selectTargetMarketId);
  if (field.model.endsWith('isPartitionedBy')) {
    return (
      <PSQEditor
        {...params}
        entity={entity}
        currentTargetMarketID={currentTargetMarketID}
      />
    );
  } else if (field.model === 'priceWaterfalls') {
    return <PriceWaterfalls {...params} entity={entity} />;
  } else if (field.model === 'hierarchyProduct') {
    return <LogisticalHierarchySelector {...params} />;
  } else if (field.model === 'textileVariantList') {
    if (field.inputKind.kind === 'pictureVariantList') {
      return <FormTextileVariantsList {...params} />;
    }
    return <VariantList {...params} entity={entity} />;
  }

  if (flags && entityKind === ENTITY_TYPE_PRODUCTVERSION) {
    if (field.model === 'typePackaging') {
      return <FormTypePackaging {...params} />;
    }
    if (field.model === 'isDisplayUnit') {
      // Do not display the isDisplayUnit field in this case
      return null;
    }
  }

  // Check if the field should be displayed if depending on other data.
  if (!shouldRenderFieldBasedOnConditions(field, entity)) {
    return null;
  }

  let queryParams;
  const permissionsFilter = (callback) => {
    const rootModel = getRootModel(field);
    if (entityPermissions && rootModel in entityPermissions) {
      return callback(entityPermissions[rootModel]);
    }
    return {};
  };

  if (field.model === 'productIdentifier') {
    return <FormProductIdentifier {...params} />;
  }

  switch (field.inputKind.kind) {
    case 'labels':
      return <Labels {...params} />;
    case 'imageCheckbox':
      return <ImageCheckbox {...params} />;
    case 'text':
      return <FormText {...params} />;
    case 'gln':
      return <FormGln {...params} />;
    case 'timestamp':
      return <FormText {...params} />;
    case 'float':
      return <FormText {...params} type="number" />;
    case 'int':
      return <FormText {...params} type="number" />;
    case 'textarea':
      return <FormTextarea {...params} />;
    case 'tags':
      return (
        <LanguageConsumer key={fieldKey}>
          {(currentLanguage) => (
            <FormTags currentLanguage={currentLanguage} {...params} />
          )}
        </LanguageConsumer>
      );
    case 'tags_without_translation':
      return <FormTags {...params} />;
    case 'list':
      if (field.declinableBy) {
        return (
          <LanguageConsumer key={fieldKey}>
            {(currentLanguage) => (
              <DeclinableField
                currentLanguage={currentLanguage}
                {...params}
                entity={entity}
              />
            )}
          </LanguageConsumer>
        );
      }
      if (field.inputKind.full) {
        // FIXME We shouldn't handle this case based on name of model
        if (/allergenTypeList/g.test(field.model)) {
          return <AllergenTypeList {...params} entity={entity} />;
        }
        return <FormFullList {...params} entity={entity} />;
      }
      return <MultiLevelProxy {...params} entity={entity} />;
    case 'checkbox':
      return <FormCheckbox {...params} />;
    case 'radio':
      return <FormRadio {...params} isEntity={!!field.inputKind.referential} />;
    case 'select':
      return <FormSelect {...params} />;
    case 'autocomplete':
      queryParams = Object.assign(
        {},
        field.inputKind.params || {},
        entityKind === ENTITY_TYPE_PRODUCTVERSION
          ? getDimensions(entity, [DIMENSION_KIND, DIMENSION_TARGET_MARKET])
          : {},
        { as_tree: !!get(field, ['options', 'asTree']) },
        permissionsFilter((filterValue) => ({
          search_code: filterValue,
        })),
        entityKind === ENTITY_TYPE_PRODUCTVERSION &&
          isRetailer &&
          field.inputKind.uri?.startsWith('onboarding')
          ? {
              organization_id: userOrganizationId,
              source_organization_id: sourceOrganizationId,
            }
          : {},
      );
      return (
        <FormAutocomplete
          {...params}
          params={queryParams}
          path={field.inputKind.url}
          autoSelect={field.inputKind.autoSelect}
        />
      );
    case 'multiAutocomplete':
      queryParams =
        entityKind === ENTITY_TYPE_PRODUCTVERSION
          ? getDimensions(entity, [DIMENSION_KIND, DIMENSION_TARGET_MARKET])
          : {};
      queryParams.as_tree = !!field.options && !!field.options.asTree;
      return <FormMultiAutocomplete {...params} params={queryParams} />;
    case 'product':
      return (
        <LanguageConsumer key={fieldKey}>
          {(currentLanguage) => (
            <FormProductAutocomplete
              currentLanguage={currentLanguage}
              {...params}
            />
          )}
        </LanguageConsumer>
      );
    case 'brand':
      return <FormBrandAutocomplete {...params} />;
    case 'kind':
      return (
        <FormKindAutocomplete
          {...params}
          entityId={entityId}
          entityKind={entityKind}
          params={permissionsFilter((filterValue) => ({
            search_ids: filterValue,
          }))}
        />
      );
    case 'date':
      return <Date {...params} />;
    case 'daterange':
      return <DateRange {...params} />;
    case 'iso_date':
      return <Date {...params} />;
    case 'dict':
      return <FormDict {...params} entity={entity} />;
    case 'flat_dict':
      return <FormDict {...params} entity={entity} isFlat />;
    case 'suppliedUnitRadio':
      return <SuppliedUnitFormRadio {...params} entity={entity} />;
    case 'node':
      return field.node;
    case 'productsegment':
      return (
        <FormProductSegmentClassifier
          {...params}
          entity={entity}
          params={permissionsFilter((filterValue) => ({
            filter_id_in: filterValue,
            with_children: Array.isArray(filterValue),
          }))}
        />
      );
    case 'string_list':
      return <FormStringList {...params} entity={entity} />;
    case 'textileVariantStringList':
      return <FormTextileVariantStringList {...params} />;
    case 'supplierIdRBA':
      return <SupplierIdRBA {...params} />;
    default:
      return null;
  }
};

/**
 * Wrap field rendering with an error boundary to avoid breacking
 * product page because of some wrong field data.
 */
export function renderField(
  entity,
  entityKind,
  entityId,
  key,
  field,
  validate?,
  hasProductUpdatePermission?,
  externalPlugins?,
  extraParams?,
) {
  return (
    <FieldErrorBoundary key={key} field={field}>
      <InternalField
        fieldKey={key}
        entity={entity}
        entityKind={entityKind}
        entityId={entityId}
        field={field}
        validate={validate}
        hasProductUpdatePermission={hasProductUpdatePermission}
        externalPlugins={externalPlugins}
        extraParams={extraParams}
      />
    </FieldErrorBoundary>
  );
}

export function shouldRenderFieldBasedOnConditions(field, entity) {
  if (
    field.filterApplicableCondition &&
    field.filterApplicableCondition.conditionSource &&
    !field.filterApplicableCondition.relativeSource &&
    Array.isArray(field.applicableConditionValues)
  ) {
    const currentCondition = extractValue(
      entity,
      field.filterApplicableCondition.conditionSource,
    );
    return field.applicableConditionValues.indexOf(currentCondition) !== -1;
  }
  return true;
}
