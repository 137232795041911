import { ReactNodeLike } from 'prop-types';
import {
  CSSProperties,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { useWindowSize } from '@alkem/react-hooks';
import { ClickOutside } from '@alkem/react-ui-click-outside';

import { setHighlightedRowIdentifier } from 'modules/catalog/product/actions';

import './panel.scss';

interface Props {
  rowIdentifier?: number;
  header?: ReactNodeLike;
  body: ReactNodeLike;
  onClose: () => void;
  cellRef: RefObject<HTMLDivElement>;
}

const ListTablePanel = ({
  rowIdentifier,
  header,
  body,
  onClose,
  cellRef,
}: Props) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const windowRect = useWindowSize();

  const [position, setPosition] = useState<CSSProperties>({
    visibility: 'hidden',
  });

  useEffect(() => {
    rowIdentifier && dispatch(setHighlightedRowIdentifier(rowIdentifier));
    return () => {
      rowIdentifier && dispatch(setHighlightedRowIdentifier(null));
    };
  }, [dispatch, rowIdentifier]);

  const getHorizontalPosition = (cellRect: DOMRect) => {
    const panelWidth = 330;

    // default display on the left of the cell
    let leftPosition = cellRect.left - panelWidth;

    // case panel overflow on the left, align panel to the right of the cell
    if (leftPosition < 0) {
      leftPosition = cellRect.right;
    }

    return { left: leftPosition };
  };

  const getVerticalPosition = (
    cellRect: DOMRect,
    windowHeight: number,
    panelHeight: number,
  ) => {
    const navBarHeight = 40;

    // default panel middle = cell middle
    let topPosition = cellRect.top + cellRect.height / 2 - panelHeight / 2;

    // case panel overflow on the bottom, align panel to bottom of the screen
    if (topPosition + panelHeight > windowHeight) {
      topPosition = windowHeight - panelHeight;
    }

    // case panel overflow on the top, align panel to top of the screen
    if (topPosition < navBarHeight) {
      topPosition = navBarHeight;
    }

    return { top: topPosition };
  };

  const computePosition = useCallback(() => {
    const cellRect = cellRef.current?.getBoundingClientRect();

    const panelRect = ref.current?.getBoundingClientRect();

    if (cellRect && windowRect.width && windowRect.height && panelRect) {
      const horizontalPosition = getHorizontalPosition(cellRect);
      const verticalPosition = getVerticalPosition(
        cellRect,
        windowRect.height,
        panelRect.height,
      );

      setPosition({
        ...horizontalPosition,
        ...verticalPosition,
      });
    } else {
      setPosition({ visibility: 'hidden' });
    }
  }, [cellRef, windowRect.height, windowRect.width, ref]);

  useEffect(() => {
    computePosition();
  }, [computePosition]);

  return (
    <div
      className="ListTablePanelContainer"
      data-testid="ListTablePanelContainer"
    >
      <ClickOutside onClickOutside={onClose}>
        <div
          className="ListTablePanel alk-flex alk-flex-column"
          style={position}
          ref={ref}
        >
          <div className="ListTablePanel__header alk-flex">
            <div className="ListTablePanel__headerTitle">{header}</div>
            <i
              className="ListTablePanel__close mdi mdi-close"
              onClick={(event) => {
                onClose();
                event.stopPropagation();
              }}
              data-testid="ListTablePanelCloseButton"
            />
          </div>
          <div className="ListTablePanel__body alk-flex alk-flex-column">
            {body}
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};

export default ListTablePanel;
