import { List, Map } from 'immutable';
import { flatten } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { SplitButton } from '@alkem/react-ui-button';

import { isActivelyShared } from 'core/api/retailerproductversion';
import {
  saveDataOpsPatches,
  selectDataOpsDirtyPatchList,
  selectDataOpsMessages,
  selectHasDataOps,
  selectIsDataOpsPatcher,
} from 'modules/data-ops';
import { selectHasSpecificFields } from 'modules/display-groups/selectors';
import { RELEASE_DATA_OPS_MESSAGES } from 'modules/feature-flag/constants';
import {
  selectHasFeature,
  selectHasLogisticalHierarchiesAdvancedPrivateFields,
} from 'modules/feature-flag/selectors';
import {
  selectArePatchedLogisticalUnitsDirty,
  selectHasLogisticalHierarchyPrivateFieldsEdited,
} from 'modules/logistical-hierarchies/selectors';
import { usePermission } from 'modules/permissions';
import {
  MANAGE_MATURITY_PERMISSION,
  PRODUCT_PERMISSION,
} from 'modules/permissions/const';
import { openMessageModal } from 'modules/product-page/modules/normalized-comments/actions';
import {
  selectIsMessageModalOpen,
  selectPendingMessages,
} from 'modules/product-page/modules/normalized-comments/selectors';
import { selectRuleSets } from 'modules/view-as/selectors';
import {
  selectCanPatchProduct,
  selectCanUpdateProduct,
  selectCanValidateProduct,
  selectHasBeenAccepted,
  selectHasNormalizedCommentsPermission,
  selectIsDirty,
  selectIsExportable,
  selectProductKey,
  selectProductVersionId,
  selectSourceProductVersion,
} from 'reducers/productVersion';
import i18n from 'utils/i18n';
import { separateActions } from 'utils/redux';
import { sortAsc } from 'utils/sort';

import {
  clearPromptedMaturityRuleSetsWithDeadlines,
  requireMaturityRules,
  requireMaturityRulesDone,
  saveRetailerProductVersion,
} from '../../../actions';
import {
  selectIsSavingInProgress,
  selectMaturityRuleSetsWithDeadlinesToBeDefined,
} from '../../../selectors';
import { PromptedMaturityRuleSet } from '../../../types';
import ProductFooterValidation from '../product-footer-actions/validation';

import './index.scss';
import { MaturityRuleSetActivationModal } from './maturity-ruleset-activation-modal';
import { MessageModal } from './message-modal';

const mapStateToProps = createStructuredSelector({
  hasNormalizedCommentPermission: selectHasNormalizedCommentsPermission,
  canUpdateProduct: selectCanUpdateProduct,
  canPatchProduct: selectCanPatchProduct,
  canValidateProduct: selectCanValidateProduct,
  pendingMessages: selectPendingMessages,
  dataOpsMessages: selectDataOpsMessages,
  hasSpecificFields: selectHasSpecificFields,
  hasDataOpsReleaseMessages: selectHasFeature(RELEASE_DATA_OPS_MESSAGES),
  productVersionId: selectProductVersionId,
  isDirty: selectIsDirty,
  isExportable: selectIsExportable,
  isMessageModalOpen: selectIsMessageModalOpen,
  hasBeenAccepted: selectHasBeenAccepted,
  saveInProgress: selectIsSavingInProgress,
  ruleSets: selectRuleSets,
  maturityRuleSetsWithDeadlinesToBeDefined:
    selectMaturityRuleSetsWithDeadlinesToBeDefined,
  patches: selectDataOpsDirtyPatchList,
  arePatchedLogisticalUnitsDirty: (state) =>
    [selectHasDataOps, selectCanPatchProduct, selectIsDataOpsPatcher].every(
      (selector) => selector(state),
    ) || selectHasLogisticalHierarchiesAdvancedPrivateFields(state)
      ? selectArePatchedLogisticalUnitsDirty(state)
      : false,
  canRequestChanges: (state) =>
    selectIsDataOpsPatcher(state) &&
    isActivelyShared(selectSourceProductVersion(state)),
  areLogisticalUnitPrivateFieldsDirty:
    selectHasLogisticalHierarchyPrivateFieldsEdited,
  productKey: selectProductKey,
});

const mapDispatchToProps = {
  saveRetailerProductVersion,
  requireMaturityRules,
  saveDataOpsPatches,
  openMessageModal,
};

interface RetailerFooterActionsProps {
  hasNormalizedCommentPermission: boolean;
  canUpdateProduct: boolean;
  canPatchProduct: boolean;
  canValidateProduct: boolean;
  hasBeenAccepted?: boolean;
  hasSpecificFields?: boolean;
  hasDataOpsReleaseMessages?: boolean;
  isDirty?: boolean;
  isExportable?: boolean;
  isMessageModalOpen: boolean;
  pendingMessages: { [key: string]: any[] };
  dataOpsMessages: object;
  productVersionId: number;
  saveInProgress: boolean;
  actions: {
    saveRetailerProductVersion(exportable: boolean): void;
    requireMaturityRules(rule: any): void;
    saveDataOpsPatches(): void;
    openMessageModal(opened: boolean): void;
  };
  ruleSets: List<Map<string, any>>;
  maturityRuleSetsWithDeadlinesToBeDefined: List<PromptedMaturityRuleSet>;
  patches: {}[];
  arePatchedLogisticalUnitsDirty?: boolean;
  areLogisticalUnitPrivateFieldsDirty?: boolean;
  canRequestChanges?: boolean;
  productKey: { id: number };
}

interface Button {
  order: number;
  key: string;
  id: string;
  text: string;
  onClick(): void;
  disabled: boolean;
}

function RetailerFooterActionsView({
  actions,
  hasNormalizedCommentPermission,
  canUpdateProduct,
  canPatchProduct,
  canValidateProduct,
  hasBeenAccepted,
  hasSpecificFields,
  hasDataOpsReleaseMessages,
  isDirty,
  isExportable,
  isMessageModalOpen,
  pendingMessages,
  dataOpsMessages,
  productVersionId,
  ruleSets,
  maturityRuleSetsWithDeadlinesToBeDefined,
  saveInProgress,
  patches,
  arePatchedLogisticalUnitsDirty,
  canRequestChanges,
  areLogisticalUnitPrivateFieldsDirty,
  productKey,
}: RetailerFooterActionsProps) {
  const dispatch = useDispatch();

  const { hasPermission } = usePermission({
    module: PRODUCT_PERMISSION,
    entityId: productKey?.id,
  });

  const requestedChanges = useMemo(
    () => (pendingMessages && pendingMessages[productVersionId]) || [],
    [pendingMessages, productVersionId],
  );

  const dataOpsPatches = useMemo(
    () =>
      (dataOpsMessages && flatten(Object.values(dataOpsMessages))).filter(
        (x) => x,
      ) || [],
    [dataOpsMessages],
  );

  const entitiesToSend = useMemo(
    () =>
      requestedChanges
        .filter((e) => !e.sent)
        .concat(dataOpsPatches.filter((e) => !e.sent)),
    [requestedChanges, dataOpsPatches],
  );

  const closeMessageModal = useCallback(
    () => actions.openMessageModal(false),
    [actions],
  );

  const onRequestChanges = useCallback(
    () => actions.openMessageModal(true),
    [actions],
  );

  const onSetExportable = useCallback(() => {
    actions.saveRetailerProductVersion(true);
  }, [actions]);

  const onSaveDataOpsPatches = useCallback(() => {
    actions.saveDataOpsPatches();
  }, [actions]);
  const onSave = useCallback(() => {
    actions.saveRetailerProductVersion(false);
  }, [actions]);

  const buttons: Button[] = [];

  /* save & exportable actions */

  if (hasBeenAccepted && !isExportable && canValidateProduct) {
    const hasChanges =
      (canUpdateProduct && isDirty) ||
      (canPatchProduct && arePatchedLogisticalUnitsDirty);
    if (hasChanges) {
      buttons.push({
        order: 300,
        key: 'save-export-button',
        id: 'save-export-button',
        text: i18n.t('Save and set as exportable'),
        onClick: onSetExportable,
        disabled: saveInProgress,
      });
      buttons.push({
        order: 301,
        key: 'save-only-button',
        id: 'save-only-button',
        text: i18n.t('Save'),
        onClick: onSave,
        disabled: saveInProgress,
      });
    } else {
      buttons.push({
        order: 302,
        key: 'export-button',
        id: 'export-button',
        text: i18n.t('Set as exportable'),
        onClick: onSetExportable,
        disabled: saveInProgress,
      });
    }
  } else if (
    hasBeenAccepted &&
    ((canUpdateProduct && hasSpecificFields) || canPatchProduct)
  ) {
    const hasChanges =
      isDirty ||
      arePatchedLogisticalUnitsDirty ||
      areLogisticalUnitPrivateFieldsDirty;
    buttons.push({
      order: 303,
      key: 'save-button',
      id: 'save-button',
      text: hasChanges ? i18n.t('Save') : i18n.t('Saved'),
      onClick: onSave,
      disabled: !hasChanges || saveInProgress,
    });
  }

  /* data-ops + request changes action */

  const patchCount = patches?.length;
  if (hasDataOpsReleaseMessages) {
    if (canPatchProduct && patchCount > 0) {
      buttons.push({
        order: 100,
        key: 'data-ops-patch-button',
        id: 'data-ops-patch-button',
        text:
          patchCount > 1
            ? i18n.t('Patch {{count}} fields', { count: patchCount })
            : i18n.t('Patch 1 field'),
        onClick: onSaveDataOpsPatches,
        disabled: saveInProgress,
      });
    }
    if (
      canRequestChanges &&
      ((hasNormalizedCommentPermission && requestedChanges.length) ||
        (canPatchProduct && patchCount > 0))
    ) {
      const notSent = entitiesToSend.length;
      let buttonLabel = i18n.t('Request sent');
      if (notSent) {
        buttonLabel =
          notSent === 1
            ? i18n.t('Request 1 change')
            : i18n.t('Request {{notSent}} changes', { notSent });
      }
      buttons.push({
        order: 90,
        key: 'data-ops-change-request-button',
        id: 'data-ops-change-request-button',
        onClick: onRequestChanges,
        text: buttonLabel,
        disabled: notSent === 0 || saveInProgress,
      });
    }
  } else {
    if (hasNormalizedCommentPermission && requestedChanges.length) {
      const notSent = entitiesToSend.length;
      let buttonLabel = i18n.t('Request sent');
      if (notSent) {
        buttonLabel =
          notSent === 1
            ? i18n.t('Request 1 change')
            : i18n.t('Request {{notSent}} changes', { notSent });
      }
      buttons.push({
        order: 200,
        key: 'request-change-footer-button',
        id: 'request-change-footer-button',
        onClick: onRequestChanges,
        text: buttonLabel,
        disabled: notSent === 0 || saveInProgress,
      });
    }

    if (canPatchProduct && patchCount > 0) {
      buttons.push({
        order: 90,
        key: 'data-ops-patch-button',
        id: 'data-ops-patch-button',
        text:
          patchCount > 1
            ? i18n.t('Patch {{count}} fields', { count: patchCount })
            : i18n.t('Patch 1 field'),
        onClick: onSaveDataOpsPatches,
        disabled: saveInProgress,
      });
    }
  }

  /* maturity action */

  const requiredMaturityRules = useMemo(() => {
    const maturityRuleSets = (ruleSets || List()).filter(
      (ruleSet) =>
        ruleSet?.get('type') === 'MATURITY' && ruleSet.get('checked'),
    );
    const activate = maturityRuleSets.some((r) => !r?.get('requested'));
    return maturityRuleSets
      .filter((ruleSet) => !!ruleSet?.get('requested') === !activate)
      .reduce<Map<string, any> | undefined>(
        (acc, ruleSet) => acc?.set(ruleSet?.get('id'), activate),
        Map(),
      );
  }, [ruleSets]);

  const onRequireMaturityRules = useCallback(() => {
    actions.requireMaturityRules(requiredMaturityRules?.toJS());
  }, [actions, requiredMaturityRules]);

  const onActivateScenarioModalClosedOrCancelled = () => {
    dispatch(clearPromptedMaturityRuleSetsWithDeadlines());
    dispatch(requireMaturityRulesDone());
  };

  const onActivateScenarioModalConfirmed = () => {
    dispatch(clearPromptedMaturityRuleSetsWithDeadlines());
  };

  if (
    !requiredMaturityRules?.isEmpty() &&
    hasPermission({ permissions: MANAGE_MATURITY_PERMISSION })
  ) {
    const count = requiredMaturityRules?.size;
    let label;

    if (requiredMaturityRules?.first()) {
      label =
        count === 1
          ? i18n.t('Activate 1 maturity rule')
          : i18n.t('Activate {{count}} maturity rules', { count });
    } else {
      label =
        count === 1
          ? i18n.t('Deactivate 1 maturity rule')
          : i18n.t('Deactivate {{count}} maturity rules', { count });
    }

    buttons.push({
      order: 100,
      key: 'maturity-rulesets-button',
      id: 'maturity-rulesets-button',
      text: label,
      onClick: onRequireMaturityRules,
      disabled: saveInProgress,
    });
  }

  /* sort actions */
  const [mainAction, ...alternativeActions] = [...buttons].sort((a, b) =>
    sortAsc(a.order, b.order),
  );

  return (
    <div className="RetailerFooterActions">
      <ProductFooterValidation />
      <div
        className="RetailerFooterActions__buttons"
        data-testid="retailer-footer-actions"
      >
        {hasBeenAccepted && isExportable && hasSpecificFields === false && (
          <div
            key="product-exportable"
            id="product-exportable"
            className="alk-flex alk-flex-center"
          >
            <i className="mdi mdi-export RetailerFooterActions__icon" />
            {i18n.t('Exportable')}
          </div>
        )}
        {mainAction && (
          <SplitButton
            {...mainAction}
            className="RetailerFooterActions__splitButton"
            alternativeOptions={alternativeActions}
            alternativeDisabled={false}
            spinner={saveInProgress}
            dropup
          />
        )}
      </div>
      {isMessageModalOpen && (
        <MessageModal
          entities={entitiesToSend}
          closeModal={closeMessageModal}
        />
      )}
      {!maturityRuleSetsWithDeadlinesToBeDefined.isEmpty() && (
        <MaturityRuleSetActivationModal
          promptedMaturityRuleSets={maturityRuleSetsWithDeadlinesToBeDefined}
          onClose={onActivateScenarioModalClosedOrCancelled}
          onConfirm={onActivateScenarioModalConfirmed}
        />
      )}
    </div>
  );
}

RetailerFooterActionsView.defaultProps = {
  hasSpecificFields: false,
};

export const RetailerFooterActions = connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions,
)(memo(RetailerFooterActionsView));
