import { Map, fromJS } from 'immutable';
import { useCallback, useEffect, useMemo } from 'react';

import { SwitchButton } from '@alkem/react-ui-button';

import {
  DisplayUnitFilter,
  DisplayUnitFilters,
} from 'constants/filters/displayUnits';
import { displayUnitsFilter } from 'core/modules/list/constants/filters/display-units';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { hasFeature } from 'modules/feature-flag';
import { FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT } from 'modules/feature-flag/constants';
import { selectHasThirdPartyPhysicalCheckStatus } from 'modules/feature-flag/selectors';
import { UserImmutable } from 'types';
import i18n from 'utils/i18n';
import { get } from 'utils/immutable';

import CollapsibleFilter from '../collapsible';
import FilterItem from '../simple/item';

import './index.scss';

const {
  consumerUnit,
  displayUnit,
  packCasePallet,
  heterogeneousHierarchyUnit,
} = DisplayUnitFilters;

interface Filter {
  id: string;
  label: string;
}

interface DisplayUnitFilterProps {
  selectedFilterMap: Map<string, any>;
  aggregations: Map<string, any>;
  user: UserImmutable;
  collapsed?: boolean;
  onChange(
    filter: {
      key: string;
      value: string;
      label?: string;
      add?: boolean;
      data?: Filter;
    },
    fromQuery?: boolean,
  ): void;
  onCollapse(filterKey: string, collapsed: boolean): void;
  filterQueryValues: [];
  withAllTypes?: boolean;
  onChangeWithAllTypes?(isSelected: boolean): void;
}

const filterKey = displayUnitsFilter.key;
const filterLabel = i18n.t(
  'frontproductstream.core.list_filter_display_unit.label',
  { defaultValue: 'Type of units' },
);

export const DisplayUnitsFilter = (props: DisplayUnitFilterProps) => {
  const {
    withAllTypes,
    user,
    filterQueryValues,
    aggregations,
    onCollapse,
    onChange,
    onChangeWithAllTypes,
    selectedFilterMap,
    collapsed,
  } = props;

  const filterList = useMemo(() => {
    const types = [consumerUnit, displayUnit, packCasePallet];
    if (hasFeature(user, FEATURE_HETEROGENEOUS_LOGISTICAL_UNIT)) {
      types.push(heterogeneousHierarchyUnit);
    }
    return fromJS(
      types
        .filter(
          (f) =>
            !f.onlyThirdParty || selectHasThirdPartyPhysicalCheckStatus(user),
        )
        .map(({ id, name }) => ({ id, label: name })),
    );
  }, [user]);

  const hasDocCount = useMemo(
    () =>
      filterList.some(
        (filter: DisplayUnitFilter) =>
          get(aggregations, [filterKey, get(filter, 'id'), 'doc_count']) >= 0,
      ),
    [aggregations, filterList],
  );

  const updateSelectionFromQuery = useCallback(() => {
    buildFiltersFromQuery({
      filterQueryValues,
      filterList,
      filterKey,
      selectFilterValue: (filter: Filter) => get(filter, 'id'),
      selectFilterLabel: (filter: Filter) =>
        `${filterLabel}: ${get(filter, 'label')}`,
      selectFilterData: (filter: Filter) => filter,
    }).then((filtersFromQuery) => {
      onChange(filtersFromQuery, true);
    });
  }, [filterList, filterQueryValues, onChange]);

  const onCollapseWithValue = useCallback(
    (isCollapsed: boolean) => {
      onCollapse(filterKey, isCollapsed);
    },
    [onCollapse],
  );

  const onChangeWithFilter = useCallback(
    (filter: Filter, selected: boolean) => {
      onChange({
        key: filterKey,
        value: get(filter, 'id'),
        label: `${filterLabel}: ${get(filter, 'label')}`,
        add: selected,
        data: filter,
      });
    },
    [onChange],
  );

  const onChangeWithAllTypesSelected = useCallback(
    (isSelected: boolean) => {
      if (onChangeWithAllTypes) {
        onChangeWithAllTypes(isSelected);
      }
      if (
        !isSelected &&
        get(selectedFilterMap, [filterKey, packCasePallet.id])
      ) {
        onChange(
          {
            key: filterKey,
            value: packCasePallet.id,
            add: false,
          },
          true,
        );
      }
    },
    [onChange, onChangeWithAllTypes, selectedFilterMap],
  );

  const hasThirdPartyPhysicalCheckStatus = useMemo(
    () => selectHasThirdPartyPhysicalCheckStatus(user),
    [user],
  );

  useEffect(() => {
    updateSelectionFromQuery();
  });

  return (
    <CollapsibleFilter
      id="list-filter-display-units"
      label={filterLabel}
      collapsed={collapsed}
      onCollapse={onCollapseWithValue}
    >
      {hasThirdPartyPhysicalCheckStatus && (
        <div className="ListDisplayUnitsFilter__switch">
          <SwitchButton
            content={i18n.t(
              'frontproductstream.core.list_filter_display_unit.show_all_types_button',
              { defaultValue: 'Show all types' },
            )}
            onChange={onChangeWithAllTypesSelected}
            checked={withAllTypes}
          />
        </div>
      )}
      {filterList.map((filter: DisplayUnitFilter) => {
        const disabled =
          get(filter, 'id') === packCasePallet.id && !withAllTypes;
        return (
          <FilterItem
            key={`list-simple-filter-${filterKey}-${get(filter, 'id')}`}
            filterKey={filterKey}
            filter={filter}
            aggregation={get(aggregations, [filterKey, get(filter, 'id')])}
            selected={
              get(filter, 'id') === packCasePallet.id
                ? !!get(selectedFilterMap, [filterKey, get(filter, 'id')]) &&
                  withAllTypes
                : !!get(selectedFilterMap, [filterKey, get(filter, 'id')])
            }
            onChange={onChangeWithFilter}
            disabled={disabled}
            hasDocCount={!disabled && hasDocCount}
          />
        );
      })}
    </CollapsibleFilter>
  );
};

DisplayUnitsFilter.defaultProps = {
  selectedFilterMap: Map(),
  aggregations: Map(),
  collapsed: true,
  filterQueryValues: [],
  withAllTypes: false,
};
