import { isFunction } from 'lodash/fp';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, matchPath, useLocation, useNavigate } from 'react-router-dom';

import {
  MFE_LOGAS_SUCCESS,
  MFE_LOGIN_SUCCESS,
  MFE_LOGOUT_EVENT,
  useSubscribe,
} from '@alkem/micro-frontend-tools';
import { Authentication } from '@alkem/sdk-dashboard';

import { resetLoading } from 'actions/navigation';
import { DatadogListener } from 'components/listeners/DatadogListener';
import { LoadingListener } from 'components/listeners/LoadingListener';
import { NotificationListener } from 'components/listeners/NotificationListener';
import { TrackerListener } from 'components/listeners/TrackerListener';
import PageTop from 'layouts/PageTop';
import { ConsentManagerChecker } from 'modules/consent-manager';
import { hasFeature } from 'modules/feature-flag';
import { HelpButton, HelpHandler, HelpPanel } from 'modules/help';
import IdlenessTracker from 'modules/idle/tracker';
import {
  AFTER_LOGAS_SUCCESS,
  AFTER_LOGIN_SUCCESS,
  LOGOUT,
  selectIsAuthorized,
  selectUser,
  tryAuthorization,
} from 'modules/user';
import * as routes from 'routes';
import { shouldResetScroll } from 'utils/history';
import { withQuery } from 'utils/query';

import { PageView } from './PageView';
import PublicRoutes from './Public';

export function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationRef = useRef(location);
  const user = useSelector(selectUser);
  const isAuthorized = useSelector(selectIsAuthorized);

  useEffect(() => {
    Authentication.addLogoutListener(() => {
      navigate(withQuery(routes.logout, { reason: 'expired_token' }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetLoading());
    if (
      !isAuthorized &&
      !PublicRoutes.some(
        (route) =>
          location.pathname !== routes.landing &&
          matchPath(route.path, location.pathname),
      )
    ) {
      dispatch(tryAuthorization(location));
    }
    if (isAuthorized && isFunction(global.Appcues?.page)) {
      global.Appcues.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (shouldResetScroll(locationRef.current, location)) {
      window.scrollTo(0, 0);
    }
    locationRef.current = location;
  }, [location]);

  useSubscribe<{ hasOrganizationSwapped?: boolean; next?: string }>(
    MFE_LOGIN_SUCCESS,
    (payload) => {
      dispatch({ type: AFTER_LOGIN_SUCCESS, payload });
    },
  );

  useSubscribe<{ next?: string }>(MFE_LOGAS_SUCCESS, (payload) => {
    dispatch({ type: AFTER_LOGAS_SUCCESS, payload });
  });

  useSubscribe<{ next?: string }>(MFE_LOGOUT_EVENT, (payload) => {
    dispatch({ type: LOGOUT, payload });
  });

  const hasSalesforceKnowledgeBase = hasFeature(
    user,
    'salesforce-knowledge-base',
  );

  return (
    <>
      {isAuthorized && <PageTop pathname={location.pathname} />}
      <ConsentManagerChecker
        bypass={!isAuthorized}
        hasSalesforceKnowledgeBase={hasSalesforceKnowledgeBase}
      >
        <Outlet />
        {isAuthorized && <PageView />}
        <TrackerListener />
      </ConsentManagerChecker>
      {isAuthorized && (
        <>
          <IdlenessTracker user={user} />
          <LoadingListener />
          <HelpHandler
            hasSalesforceKnowledgeBase={hasSalesforceKnowledgeBase}
          />
          {hasSalesforceKnowledgeBase && (
            <>
              <HelpButton />
              <HelpPanel />
            </>
          )}
        </>
      )}
      <NotificationListener />
      <DatadogListener />
    </>
  );
}
