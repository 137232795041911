import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';

import {
  MFE_LOGOUT_EVENT,
  publish,
  withQueryParams,
} from '@alkem/micro-frontend-tools';

import * as routes from 'routes';

import { logout } from './actions';

export function Logout() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');
  const error = searchParams.get('reason');
  const logoutPayload = next ? { next } : {};

  useEffect(() => {
    dispatch(logout(logoutPayload));
    return () => {
      publish(MFE_LOGOUT_EVENT, logoutPayload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Navigate to={withQueryParams(routes.login, error ? { error } : {})} />
  );
}
