import { Map } from 'immutable';
import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Referential } from '@alkem/sdk-dashboard';

import { kindFilter } from 'core/modules/list/constants/filters/kind';
import { buildFiltersFromQuery } from 'core/modules/list/utils/filters';
import { fetchReferential } from 'core/modules/with-referential/actions';
import { selectReferential } from 'core/modules/with-referential/selectors';
import { useDispatch } from 'hooks/useDispatch';
import { FilterAggregation } from 'types';
import i18n from 'utils/i18n';
import { get, toJsIfImmutable } from 'utils/immutable';

import CollapsibleAdvancedFilter from '../advanced';

const REFERENTIAL = 'kinds';
const filterKey = kindFilter.key;
interface KindFilterProps {
  selectedFilterMap?: Map<string, any>;
  aggregations?: Map<string, FilterAggregation>;
  filterConfig: Map<string, any>;
  onChange: ComponentProps<typeof CollapsibleAdvancedFilter>['onChange'];
  onCollapse: ComponentProps<typeof CollapsibleAdvancedFilter>['onCollapse'];
  onFilter: ComponentProps<typeof CollapsibleAdvancedFilter>['onFilter'];
  onChangePage: ComponentProps<
    typeof CollapsibleAdvancedFilter
  >['onChangePage'];
  filterQueryValues?: any[];
}
export function KindFilter(props: KindFilterProps) {
  const {
    aggregations,
    filterQueryValues,
    onChange,
    selectedFilterMap,
    onFilter,
    filterConfig,
    onCollapse,
    onChangePage,
  } = props;
  const dispatch = useDispatch();
  const filterList: FilterAggregation[] | undefined = useMemo(
    () => aggregations?.valueSeq().toList().toJS(),
    [aggregations],
  );
  const filterLabel = i18n.t('frontproductstream.core.list_filter_kind.label', {
    defaultValue: 'Product Categories (Kinds)',
  });
  const referentialEntities: { entitiesById: { [key: string]: Referential } } =
    useSelector<any, Function>(selectReferential)(REFERENTIAL);

  const referentialEntitiesById: { [key: string]: Referential } | undefined =
    useMemo(
      () => toJsIfImmutable(get(referentialEntities, ['entitiesById'])),
      [referentialEntities],
    );
  const referentialFlattenedEntities:
    | { [key: string]: Referential }
    | undefined = useMemo(
    () => toJsIfImmutable(get(referentialEntities, ['flattenedTreeEntities'])),
    [referentialEntities],
  );

  const referentialFlattenedEntitiesById:
    | { [key: string]: Referential }
    | undefined = useMemo(
    () =>
      toJsIfImmutable(get(referentialEntities, ['flattenedTreeEntitiesById'])),
    [referentialEntities],
  );
  const referentialEntitiesList: Referential[] | undefined = useMemo(
    () =>
      Object.values(referentialEntitiesById || {}).sort(
        (a, b) => (a.label ?? '').localeCompare(b.label ?? '') || 0,
      ),
    [referentialEntitiesById],
  );
  const selectors = useMemo(() => {
    const selectId = (filter) => get(filter, 'id');
    const selectLabel = (filter) =>
      referentialFlattenedEntitiesById?.[selectId(filter)]?.label || '';
    const selectChildren = (filter) =>
      referentialFlattenedEntitiesById?.[selectId(filter)]?.children || [];
    return { selectId, selectLabel, selectChildren };
  }, [referentialFlattenedEntitiesById]);

  const getFilters = () => {
    if (filterConfig.get('query')) {
      return {
        filters: referentialFlattenedEntities,
      };
    }
    return {
      filters: referentialEntitiesList,
    };
  };
  useEffect(() => {
    referentialEntities ||
      dispatch(fetchReferential(REFERENTIAL, { as_tree: true }));
  }, [dispatch, referentialEntities]);

  const fillHierarchyPath = useCallback(
    (filter) => {
      const hierarchyMap = referentialFlattenedEntitiesById;
      let parentId = hierarchyMap?.[filter.value]?.parent?.id;
      while (parentId) {
        filter.data.push(parentId);
        parentId = hierarchyMap?.[parentId]?.parent?.id;
      }
      return filter;
    },
    [referentialFlattenedEntitiesById],
  );

  const onChangeFilter = (filter) => {
    if (get(filterConfig, ['query'])) {
      fillHierarchyPath(filter);
    }
    onChange(filter);
  };

  const updateSelectionFromQuery = useCallback(() => {
    buildFiltersFromQuery({
      filterQueryValues,
      filterList,
      filterKey,
      selectFilterValue: (filter) => selectors.selectId(filter),
      selectFilterLabel: (filter) =>
        `${filterLabel}: ${selectors.selectLabel(filter)}`,
      selectFilterData: (filter) => [selectors.selectId(filter)],
    }).then((filtersFromQuery) => {
      filtersFromQuery.sort((a, b) => (b.doc_count || 0) - (a.doc_count || 0));

      filtersFromQuery.forEach((filter) => {
        fillHierarchyPath(filter);
      });
      onChange(filtersFromQuery, true);
    });
  }, [
    fillHierarchyPath,
    filterLabel,
    filterList,
    filterQueryValues,
    onChange,
    selectors,
  ]);

  useEffect(() => {
    if (
      !aggregations?.isEmpty() &&
      selectedFilterMap?.isEmpty() &&
      referentialEntitiesById
    ) {
      updateSelectionFromQuery();
    }
  }, [
    aggregations,
    referentialEntitiesById,
    selectedFilterMap,
    updateSelectionFromQuery,
  ]);

  const { filters } = getFilters();
  return (
    <CollapsibleAdvancedFilter
      id="list-filter-kind"
      filterKey={filterKey}
      filterLabel={filterLabel}
      filters={filters}
      selectedFilterMap={selectedFilterMap}
      aggregations={aggregations}
      searchPlaceholder={i18n.t(
        'frontproductstream.core.list_filter_kind.search_placeholder',
        { defaultValue: 'Search for kinds' },
      )}
      searchQuery={get(filterConfig, ['query'])}
      page={get(filterConfig, ['page'])}
      collapsed={filterConfig.get('collapsed')}
      onChange={onChangeFilter}
      onCollapse={onCollapse}
      onFilter={onFilter}
      onChangePage={onChangePage}
      selectors={selectors}
      withPagination
      forceDocCount
      hasMissingFilter
      withTree
    />
  );
}
KindFilter.defaultProps = {
  selectedFilterMap: Map(),
  aggregations: Map(),
  filterConfig: Map(),
  filterQueryValues: [],
};
