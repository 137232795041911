import { get } from 'lodash';

import { ReferentialApi } from '@alkem/sdk-dashboard';

import { getUserLanguage as acceptLanguage } from 'utils/i18n';

const options = {
  cacheEnabled: true,
  acceptLanguage,
  immutable: true,
};
const referentialApi = new ReferentialApi(options);

export default referentialApi;

export const regularReferentialApi = new ReferentialApi({
  ...options,
  immutable: false,
});

const fetchSpecificReferentials = async (
  methodName,
  { queries = {}, cache = true } = {},
) => {
  try {
    const response = await referentialApi[methodName]({
      queries,
      cache,
    });
    return { referentials: get(response, 'data.data') };
  } catch (error) {
    return { error };
  }
};

export const fetchColumnReferentials = (opt) =>
  fetchSpecificReferentials('getListColumnReferentials', opt);

export const fetchReferentials = async (
  referential,
  { immutable = true, ...queries } = {},
) => {
  try {
    const response = await (immutable
      ? referentialApi
      : regularReferentialApi
    ).ReferentialGetList(referential, queries);
    return { referentials: get(response, 'data.data') };
  } catch (error) {
    return { error };
  }
};
export const fetchTargetMarketReferentials = (queries) =>
  fetchReferentials('targetmarkets', queries);
